import React from "react";
import './footer.css'
import { Container, Col, Row, ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/logo.png'


const quick_links=[
  {
    path:'/home',
    display:'Home'
  },
  {
    path:'/about',
    display:'About'
  },
  {
    path:'/gallery',
    display:'Gallery'
  },
  
];

const quick_links2=[
  {
    path:'/gallery',
    display:'Gallery'
  },
]



const Footer = () => {

  const year = new Date().getFullYear()

  return( 
  <footer className="footer">
    <Container>
      <Row>
        <Col lg='3'>
          <div className="logo">
            <img src={logo} alt="" />
            <p>Dealer in all kinds of scraps Purchase and General Supply.
            </p>

            <div className="social__links d-flex align-items-center gap-4">
            <span><Link to='https://www.youtube.com/Samuel_Orukotan_Investment_Ltd'><i class='ri-youtube-line'></i></Link></span>
            <span><Link to='https://www.facebook.com/Samuel_Orukotan_Investment_Ltd'><i class='ri-facebook-circle-line'></i></Link></span>
            <span><Link to='https://www.instagram.com/Samuel_Orukotan_Investment_Ltd'><i class='ri-instagram-line'></i></Link></span>
            </div>
          </div>
        </Col>
        <Col lg='2'>
          <h5 className="footer__link-title">Discover</h5>

          <ListGroup className="footer__quick-links">{quick_links.map((item, index)=>(
            <ListGroupItem key={index} className="ps-0 border-0">
              <Link to={item.path}>{item.display}</Link>
            </ListGroupItem>
          ))}</ListGroup>
        </Col>

        <Col lg='4'>
          <h5 className="footer__link-title">Our Location</h5>
          <ListGroup className="footer__quick-links">
  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-map-pin-line'></i></span>
      Address 1:
    </h6>

    <p className="mb-0">Engr. Idowu Road, Opp. TCN Gate, Powerline, Osogbo</p>
  </ListGroupItem>
  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-map-pin-line'></i></span>
      Address 2:
    </h6>

    <p className="mb-0">Beside MRS Filling Station, Powerline, Osogbo</p>
  </ListGroupItem>

  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-map-pin-line'></i></span>
      Address 3:
    </h6>

    <p className="mb-0">Igbona Market Area, Osogbo.</p>
  </ListGroupItem>
  </ListGroup>
        </Col>

          
        
        <Col lg='3'>
        <h5 className="footer__link-title">Contact</h5>

<ListGroup className="footer__quick-links">
  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-mail-line'></i></span>
      Email:
    </h6>

    <p className="mb-0">sammyorukutan@gmail.com</p>
  </ListGroupItem>
  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-phone-fill'></i></span>
      Phone 1:
    </h6>

    <p className="mb-0">+234 813 193 3304</p>
  </ListGroupItem>

  <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
    <h6 className="mb-0 d-flex align-items-center gap-2">
      <span><i class='ri-phone-fill'></i></span>
      Phone 2:
    </h6>

    <p className="mb-0"> +234 903 364 0171</p>
  </ListGroupItem>
  </ListGroup>
        </Col>
        <Col lg='12' className="text-center pt-5">
          <p className="copyright">Copyright {year}, design and develop by Best Choice Digital Inc. All rights reserved.</p>
        </Col>
      </Row>
    </Container>
  </footer>
  )
}

export default Footer