import React from "react";
import './newsletter.css';
import { Container, Col, Row } from "reactstrap";
import maleTourist from '../assets/images/male-tourist.png';

const Newsletter = () => {
  return( 
  <section className="newsletter">
    <Container>
        <Row>
            <Col lg='6'>
                <div  className="newsletter__content" >
                    <h2>Questions? Send us a Message!</h2>
                        <div className="newsletter__input">
                            <input type="email" placeholder="Enter your email" />
                            </div>
                            <div className="newsletter__input"><input type="email" placeholder="Enter your email" /></div>
                            
                            
                        
                        <button className="btn newsletter__btn">Subscribe</button>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
                            Voluptatem eveniet quos error. Odit, rem nemo.</p>
                </div>
            </Col>
        </Row>
    </Container>
  </section>
  )
}

export default Newsletter