import React from "react";
import { Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/login.css';
import registerImg from '../assets/images/register.png';
import userIcon from '../assets/images/user.png';




const Contact = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg='10' className="m-auto">
            <div className="login__container d-flex justify-content-between">
              <div className="login__img">
                <img src={registerImg} alt="" />
              </div>
              <div className="login__form">
                <div className="user">
                  <img src={userIcon} alt="" />
                </div>
                <h2>Feel free to write us</h2>
                

                <Form >
                <FormGroup >
                  <label htmlFor="first_name">First Name</label>
                    <input 
                        type="text" 
                        name="first_name"
                        id="first_name"
                        placeholder="First Name"   />
                  </FormGroup>
                  <FormGroup>
                  <label htmlFor="last_name">Last Name</label>
                    <input 
                        type="text" 
                        placeholder="Last Name" 
                        id="last_name"  />
                  </FormGroup>

                  <FormGroup>
                  <label htmlFor="phone_number">Phone Number</label>
                    <input 
                        type="text" 
                        placeholder="Phone Number" 
                        id="phone_number"  />
                  </FormGroup>

                  <FormGroup>
                  <label htmlFor="email">Email</label>
                    <input 
                        type="email" 
                        placeholder="example@gmail.com" 
                        id="email"  />
                  </FormGroup>

                  <FormGroup>
                    <div>
                  <label htmlFor="subject">Subject</label>
                    <input 
                        type="text" 
                        placeholder="Let us know how we can help you" 
                        id="subject"  />
                        </div>
                        </FormGroup>
                    
                        <FormGroup>
                        
                          <label htmlFor="message">Your Message</label>
                          <input
                          rows="8"
                            type="text"
                            name="message"
                            id="message"
                            placeholder="Leave a comment......."
                            className="form_input mt-1"
                             />
                        </FormGroup>
                  <Button className="btn secondary__btn auth__btn" type="submit">Submit</Button>
                </Form>
                
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact