import React from "react";
import '../styles/home.css';
import { Container, Row, Col } from 'reactstrap';
import heroImg from '../assets/images/hero-img01.jpg';
import heroImg02 from '../assets/images/hero-img02.jpg';
import heroVideo from '../assets/images/hero-video.jpg';
import worldImg from '../assets/images/world.png';
import experienceImg from '../assets/images/experience.png';
import Subtitle from './../shared/Subtitle';

import ServiceList from "../services/ServiceList";
import Testimonial from "../components/Testimonial/Testimonial";
import Gallery from "./Gallery"
import CountUp from "react-countup";


const Home = () => {
  return (
  <>
  {/* ========== hero section start =============*/}
  <section>
    <Container>
      <Row>
        <Col lg='6'>
          <div className="hero__content">
            <div className="hero__subtitle d-flex align-items-center ">
              <Subtitle subtitle={'Top Recycler for Scrap Metals'}/>
              <img src={worldImg} alt="" />
            </div>
            <h1>The world of secondary raw materials at your  <span className="highlight">fingertips</span></h1>
            <p>Samuel Orukotan Investments Ltd purchases non-ferrous and ferrous metals for end of life recycling.</p>
            <p>As an industrial manufacturer, whether you want to buy or sell, you'll receive our full support in managing your metal scrap. We work closely with top recyclers and smelters to ensure you make the most of your capacity, offering an All-in-One solution tailored to your needs.</p>
            <p>As a result, you save time, costs, and energy, while also making a positive impact on the environment.</p>
          </div>
        </Col>

        <Col lg='2'>
          <div className="hero__img-box">
            <img src={heroImg} alt="" />
          </div>
        </Col>
        <Col lg='2'>
          <div className="hero__img-box mt-4">
            <img src={heroVideo} alt="" />
          </div>
        </Col>
        <Col lg='2'>
          <div className="hero__img-box hero__video-box mt-5">
            <img src={heroImg02} alt="" />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  {/* ========== hero section start =============*/}
  <section>
    <Container>
      <Row>
        <Col lg='3'>
          <h5 className="services__subtitle">Services</h5>
          <h2 className="services__title">We offer our best services</h2>
        </Col>
        <ServiceList />
      </Row>
    </Container>
  </section>
  <section>
    <Container>
      <Row>
        <Col lg='6'>
          <div className="experience_content">
            <Subtitle subtitle={'Experience'} />

            <h2>With all our experiences <br /> we will serve you better</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
            <br /> Aspernatur, nostrum quae expedita rerum blanditiis.</p>
          </div>

          <div className="counter__wrapper d-flex align-items-center gap-5">
            <div className="counter__box">
              <span><CountUp start={8} end={12} duration={4} />K+</span>
              <h6>Successful transaction</h6>
            </div>
            <div className="counter__box">
              <span><CountUp start={0.4} end={2} duration={4} />k+</span>
              <h6>Regular clients</h6>
            </div>
            <div className="counter__box">
              <span><CountUp start={12} end={15} duration={4} /></span>
              <h6>Years experience</h6>
            </div>
          </div>
        </Col>
        <Col lg='6'>
          <div className="experience__img">
           <img src={experienceImg} alt="" /> 
        </div></Col>
        
      </Row>
    </Container>
  </section>
  {/* ========== experience tour section end =============*/}

  {/* ========== gallery section start =============*/}
    <Gallery />
  {/* ========== gallery section end =============*/}

  {/* ========== testimonial section start =============*/}
  <Container>
    <Row>
      <Col lg='12'>
        <Subtitle subtitle={'Customers Love'} />
        <h2 className="testimonial__title">Hear what our satisfied Customers had to say</h2>
      </Col>
      <Col lg='12'>
      <Testimonial />
      </Col>
    </Row>
  </Container>
  {/* ========== testimonial section end =============*/}

  {/* ========== Newsletter section start =============*/}
  {/* <Newsletter /> */}
  {/* ========== Newsletter section end =============*/}

  </>
  )
}

export default Home