import React from "react";
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from "./../pages/Home";
import Tours from "./../pages/Tours";
import TourDetails from "./../pages/TourDetails";
import Login from "./../pages/Login";
import Contact from "./../pages/Contact";
import SearchResultList from "./../pages/SearchResultList";
import ThankYou from "../pages/ThankYou";
import AboutUs from "../pages/AboutUs";
import Gallery from "../pages/Gallery";

const Routers = () => {
  return (
    <Routes>
        <Route path="/" element={<Navigate to="/home"/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/tours" element={<Tours/>} />
        <Route path="/tours/:id" element={<TourDetails/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/thank-you" element={<ThankYou/>} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/tours/search" element={<SearchResultList/>} />
    </Routes>
  )
}

export default Routers