import React from "react";
import { Container, Row, Col } from "reactstrap";
import '../styles/tour.css'
import Subtitle from "../shared/Subtitle";



const about = () => {

  return (
    <>
    <section>
      <Container>
        <Row>
        </Row>
      </Container>
      </section>
    <section>
    <Container>
      <Row>
        
        <Col lg='12'>
          <div className="experience_content">
            <Subtitle subtitle={'About Us'} />
            <h4><li>Who We Are</li></h4>
            <p>Samuel Orukotan Investments Ltd is a company involved in purchase and sales of all kinds of Scrap metals like copper, Aluminum, Zinc, leather e.t.c and other  residual product and bye-products of metals of all grades. Typically conversion of waste to wealth
Our offices are strategically located in Nigeria hence gibing us much desired position to participate in the nation building exercise. </p>
<p>We also render consultancy services and engagements to various private firms/companies and government at various levels in our area of specialization which is creation of wealth through wastes. </p>
<p>The service rendered through our expertise and experience is nothing but excellent and have been recognized and appreciated by our various clients. Our commitment to values like truthfulness, faithfulness, tenacity, accountability at well as quality and prompt services has given us the competitive edge over our contemporaries in the business.</p>
<p>Samuel Orukotan Investments Ltd was incorporated in Nigeria with RC: 1240620 as a limited liability company under the companies and allied matter act 1990.
</p>
            
            <h4><li>Service We Provide</li></h4>
            <p><li>Non-Ferrous Processing:</li> 
            <p>Samuel Orukotan Investments Ltd is in the business of non-ferrous metals recycling.We buys, processes, and sells all grades of non-ferrous scrap metal.</p>
            <p>Non-Ferrous scrap consists of base metals that are non-magnetic.</p> 
            <p>We offer non-ferrous processing services to the general public, demolition companies, and private businesses in order to help the sustainably of the environment, all while maximizing efficiencies and revenues for you.</p>
              <p><li>Ferrous Processing: </li></p>
              <p>Ferrous scrap metal is the base metal that sticks to a magnet. </p>
              <p>Samuel Orukotan Investments Ltd offers ferrous processing, buys and sells all grades of scrap metal, which creates a major benefit to our environment. We provide expert ferrous processing services to a host of clients, including private businesses, demolition companies and the general public.</p>
              <p>Our trucks, cranes, excavators, car crushers, loggers and balers make it easy to process just about any material.</p>
               <p>Your ferrous scrap metal can provide you with additional revenue, while provide you with additional revenue, while providing all of us with a cleaner and healthier environment. </p>
              <p>Call or visit any of our scrap yard or recycling centers for more information.</p>
              </p>

            

          </div>
        </Col>
      </Row>
    </Container>
  </section>

    
    </>
  )
}

export default about