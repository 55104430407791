import React from "react";
import Slider from 'react-slick';
import ava01 from '../../assets/images/avatar.jpg';
import ava02 from '../../assets/images/avatar.jpg';
import ava03 from '../../assets/images/avatar.jpg';

const Testimonial = () => {

    const settings={
        dots:true,
        infinite:true,
        autoplay:true,
        speed:1000,
        swipeToSlide:true,
        autoplaySpeed: 2000,
        slidesToShow:3,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll : 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll : 1,
                    infinite: true,
                    dots: true,

            },
        },
        ]
    }

  return (
    <Slider {...settings}>
    <div className="testimonial_ py-4 px-3">
        <p>Actually Your company has been a great company to me and my business at large. </p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava01} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mrs. Dada Mary</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>

    <div className="testimonial_ py-4 px-3">
        <p>Great prices and a clean lot that won't pop tires. Helpful guiding staff.</p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava02} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mr. Femi</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>

    <div className="testimonial_ py-4 px-3">
        <p>Great experience. Very fair prices.</p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava03} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mrs. D1</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>

    <div className="testimonial_ py-4 px-3">
        <p>I testify that student company is the best among all, a company like no other, very reliable, tested and trusted, instant payment and a pleasant attendant to customers. Infact i have never regret working with them.</p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava01} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mr. Arowolo</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>

    <div className="testimonial_ py-4 px-3">
        <p>To me Student is a great man worldbest a man that want others to be successful, your company is a great company, you people are doing great because without the boss inpart in my life, my life would be nothing, I start with nothing and he bring me up to fucking millionaire, your company is awesome.</p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava02} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mr. Lukman Jamani</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>

    <div className="testimonial_ py-4 px-3">
        <p>Price is Good and Selection is okay.</p>

            <div className="d-flex align-items-center gap-4 mt-3">
                <img src={ava03} className="w-25 rounded-2" alt="" />
            <div>
            <h6 className="mb-0 mt-3">Mr. Kenneth</h6>
            <p>Customer</p>
            </div>
            </div>
    </div>
    </Slider>
  )
}

export default Testimonial