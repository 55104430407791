import React from "react";
import ServicesCard from "./ServicesCard";
import { Col } from "reactstrap"

import weatherImg from '../assets/images/weather.png'
import guideImg from '../assets/images/guide.png'
import customizationImg from '../assets/images/customization.png'

const servicesData = [
    {
        imgUrl: weatherImg,
        title: "Non-Ferrous Processing",
        desc: "Samuel Orukotan Investments Ltd is in the business of non-ferrous metals recycling.",
    },
    {
        imgUrl: guideImg,
        title: "Ferrous Processing",
        desc: "Ferrous scrap metal is the base metal that sticks to a magnet.",
    },
    {
        imgUrl: customizationImg,
        title: "Experienced Industry Leadership",
        desc: "By building tight-knit national and international trading relationship over the years, Samuel Orukotan Investments Ltd has built its reputation as a topnotch scrap metal brokerage that has the capacity to handle the buying and selling needs of its customers in the industry.",
    },
]
const ServiceList = () => {
  return( 
  <>
  {
    servicesData.map((item, index)=> 
    <Col lg='3' md='6' sm='12' className="mb-4" key={index}><ServicesCard item={item}/></Col>)
  }
  </>)
}

export default ServiceList